<template>
  <v-row class="rowDialog">
    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="430"
      class="inputs-dialog"
    >
      <template v-slot:activator="{ on }">
        <button class="btnNewRequest" @click="openModal" v-on="on">Nova Solicitação</button>
      </template>
      <v-card class="contentCard">
        <v-card-title class="headline">
          <v-col cols="10" class="modal-title">Nova Solicitação</v-col>
          <v-spacer></v-spacer>
          <v-col class="icon" cols="1">
            <v-icon @click="closeModal" class="close-icon">mdi-window-close</v-icon>
          </v-col>
          <v-divider class="mx-6 ml-6 divider divider-consult"></v-divider>

          <v-alert class="alert" dense outlined v-if="responseError">{{responseError}}</v-alert>
        </v-card-title>

        <v-card-text class="modal-form">
          <v-container class="contentForm" v-if="showModal">
            <v-form class="form" v-if="!requestSuccess" ref="form" @submit.prevent="submit">
              <v-row v-if="!selectValue">
                <v-col cols="12" class="iconSearchFilters">
                  <v-text-field
                    v-model="document"
                    label="CNPJ"
                    v-mask="'##.###.###/####-##'"
                    :rules="validationCNPJ"
                    placeholder="Digite o CNPJ"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="iconSearchFilters">
                  <v-text-field
                    v-model="dataRequest.name"
                    label="Razão social"
                    :rules="validationSocial"
                    placeholder="Preenchimento automatico"
                    hide-details="auto"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" v-if="dataRequest.balance" class="iconSearchFilters">
                  <v-text-field
                    :value="service.formatCurrency(dataRequest.balance)"
                    label=" Saldo disponivel"
                    :rules="validationSaldo"
                    hide-details="auto"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="iconSearchFilters">
                  <v-text-field
                    v-model="dataRequest.externalId"
                    type="number"
                    label="Pedido"
                    v-mask="'#############'"
                    placeholder="Digite o número do pedido"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="iconSearchFilters">
                  <v-text-field
                    v-if="!selectValue"
                    v-model="dataRequest.valueOriginal"
                    label="Valor a ser solicitado"
                    :rules="validationNewValue"
                    v-money="money"
                    validate-on-blur
                    required
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  class="iconSearchFilters attach-mobile"
                  v-if="$vuetify.breakpoint.smAndDown"
                >
                  <v-file-input
                    label="Anexo"
                    multiple
                    small-chips
                    :rules="counterFiles"
                    v-model="fileSelected"
                  ></v-file-input>
                </v-col>

                <v-col
                  cols="12"
                  class="iconSearchFilters attach"
                  v-if="$vuetify.breakpoint.mdAndUp"
                >
                  <v-file-input
                    label="Anexo"
                    multiple
                    small-chips
                    :rules="counterFiles"
                    v-model="fileSelected"
                  ></v-file-input>
                </v-col>

                <v-col class="terms">
                  <v-checkbox
                    class="terms-text"
                    :rules="acceptTerms"
                    v-model="termsOfAcceptance"
                    color="#d70472"
                    label="Li e concordo com os termos de uso"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row v-if="selectValue">
                <v-col cols="12" class="messageValue">
                  <h5>
                    Favor selecionar algum dos valores semelhantes sugeridos abaixo para dar
                    continuidade a solicitação.
                  </h5>
                </v-col>
                <v-col cols="12">
                  <v-select
                    class="select-value"
                    :rules="validationValue"
                    v-model="dataRequest.value"
                    :items="validValues.availableValues.map((el, index) => {
                    return `${index+1} - ${service.formatCurrency(el.value)}`;
                  })"
                    label="Valor a ser selecionado"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-row class="success-text" v-if="requestSuccess">
            <v-col cols="12">
              <img class="imgSuccess" src="@/assets/success.png" />
              <h5 class="succesTitle">Solicitação de pagamentos realizada com sucesso.</h5>
              <span class="successDescription">
                A solicitação de pagamento foi solicitada para o Lojista,
                após a aprovação o valor será transferido na sua conta.
              </span>
            </v-col>
          </v-row>
        </v-card-text>
        <v-row class="buttons-actions">
          <v-btn v-if="selectValue && !requestSuccess" @click="backSteper" class="btnBack">voltar</v-btn>
          <v-btn
            v-if="!requestSuccess"
            @click="sendRequest"
            class="btnRequest"
            :loading="btnLoading"
          >Solicitar</v-btn>
          <v-btn v-if="requestSuccess" @click="closeModal" class="btnClose">Fechar</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
import { VMoney } from "v-money";
import { mapActions, mapGetters, mapMutations } from "vuex";
import service from "../../../service";

export default {
  directives: {
    mask,
    money: VMoney,
  },
  data() {
    return {
      btnLoading: false,
      showModal: true,
      dialog: false,
      singleStatus: true,
      fileSelected: [],
      termsOfAcceptance: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
      },
      dataRequest: {},
      requestError: "requestError",
      requestSuccess: false,
      selectValue: false,
      document: "",
      service,
    };
  },
  watch: {
    dialog(value) {
      if (value === false) {
        this.closeModal();
      }
    },
    document(value) {
      if (value && value.length === 18) {
        this.requestFromApi(value);
      }
    },
    fileSelected(value) {
      let i;
      let size = 0;
      for (i = 0; i < value.length; i += 1) {
        size += 35;
        document.getElementsByClassName(
          "attach"
        )[0].style.minHeight = `${size}px`;
      }
      if (value.length === 0) {
        document.getElementsByClassName("attach")[0].style.minHeight = "0px";
      }
      for (i; i > value.length; i -= 1) {
        size -= 35;
        document.getElementsByClassName(
          "attach"
        )[0].style.minHeight = `${size}px`;
      }
    },
  },
  computed: {
    ...mapGetters({
      getSellerRequest: "Request/getSellerRequest",
      getError: "Request/getError",
      getAvailableValuesForRequest: "Request/getAvailableValuesForRequest",
      getNewTransfer: "Request/getNewTransfer",
      getFilesUpload: "Request/getFilesUpload",
    }),
    validationCNPJ() {
      return [
        (value) => !!value || "CNPJ obrigatório",
        (value) => (value && value.length > 17) || "Deve conter 14 números",
      ];
    },
    validationSaldo() {
      return [(value) => !!value || "Saldo obrigatório"];
    },
    validationNewValue() {
      return [
        (value) =>
          (value && this.service.cleanCurrency(value) > 0) ||
          "Valor obrigatório",
      ];
    },
    counterFiles() {
      return [
        (value) => value.length < 11 || "É permitido no máximo 10 anexos",
      ];
    },
    validationValue() {
      return [(value) => !!value || "Valor obrigatório"];
    },
    validationSocial() {
      return [(value) => !!value || "Razão social obrigatório"];
    },
    acceptTerms() {
      return [
        (value) => !!value || "Deve-se está de acordo com os termos de uso",
      ];
    },
    getRequest() {
      return this.getSellerRequest;
    },
    responseError() {
      return this.getError;
    },
    validValues() {
      const newValues = this.getAvailableValuesForRequest;
      return newValues;
    },
    succesNewTransfer() {
      return this.getNewTransfer;
    },
    successFilesUpload() {
      return this.getFilesUpload;
    },
  },
  methods: {
    ...mapActions({
      setSellerSolicitation: "Request/setSellerSolicitation",
      getValuesAvailableForRequest: "Request/getValuesAvailableForRequest",
      setNewTransfer: "Request/setNewTransfer",
      setFilesUpload: "Request/setFilesUpload",
    }),
    ...mapMutations({
      CLEAR_NEW_TRANSFER: "Request/CLEAR_NEW_TRANSFER",
      CLEAR_ERROR: "Request/CLEAR_ERROR",
    }),
    async requestFromApi(_payload) {
      const cnpj = this.service.cleanDocument(_payload);
      await this.setSellerSolicitation(cnpj);
      this.dataRequest = this.getRequest;
    },

    closeModal() {
      this.dialog = false;
      this.requestSuccess = false;
      this.selectValue = false;
      this.optionsValue = "";
      this.showModal = false;
      this.document = "";
      this.dataRequest = {};
      this.termsOfAcceptance = false;
      this.fileSelected = [];
      this.CLEAR_NEW_TRANSFER();
      this.CLEAR_ERROR();
    },
    backSteper() {
      this.dataRequest.value = null;
      this.selectValue = false;
    },
    openModal() {
      this.showModal = true;
    },
    async sendRequest() {
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        if (!this.dataRequest.value) {
          await this.verifyAvailableValues();
          if (!this.responseError) this.selectValue = true;
        } else {
          await this.newTransfer();
          if (!this.responseError) await this.uploadFiles();
          else return;
          if (!this.responseError) {
            this.requestSuccess = true;
            this.$emit("setRequests", { perPage: 10 });
          }
        }
      }
    },
    async verifyAvailableValues() {
      const args = {
        document: this.service.cleanDocument(this.document),
        termsOfAcceptance: "S",
        value: parseFloat(
          this.service.cleanCurrency(this.dataRequest.valueOriginal)
        ),
      };
      await this.getValuesAvailableForRequest(args).then(() => {
        this.btnLoading = false;
      });
    },
    async newTransfer() {
      const payload = this.mountPayload();
      await this.setNewTransfer(payload).then(() => {
        this.btnLoading = false;
      });
    },
    async uploadFiles() {
      if (this.fileSelected.length > 0) {
        const fd = new FormData();
        this.fileSelected.map((el) => {
          fd.append("files", el);
          return true;
        });
        await this.setFilesUpload({ id: this.succesNewTransfer._id, fd }).then(
          () => {
            this.btnLoading = false;
          }
        );
      } else {
        this.requestSuccess = true;
        this.$emit("setRequests", { perPage: 10 });
      }
    },
    mountPayload() {
      const splitValue = this.dataRequest.value.split(" ");
      const { scheduleTransferDetail } = this.validValues.availableValues[
        parseInt(splitValue[0], 10) - 1
      ];
      const newArray = scheduleTransferDetail.map((el) => ({
        amount: el.amount,
        id: el.id,
        efetiveDate: el.efetiveDate,
        transferId: el.transferId,
      }));
      const transferId = this.validValues.availableValues[
        parseInt(splitValue[0], 10) - 1
      ].transferId.toString();
      const payloadObject = {
        externalId: parseInt(this.dataRequest.externalId, 10),
        document: this.service.cleanDocument(this.document),
        transferId,
        scheduleTransferDetail: newArray,
        value: this.validValues.availableValues[parseInt(splitValue[0] - 1, 10)]
          .value,
        valueOriginal: parseFloat(
          this.service.cleanCurrency(this.dataRequest.valueOriginal)
        ),
      };
      return payloadObject;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/supplier/newRequest.scss";
</style>
