<template>
  <v-row class="rowDialog">
    <v-dialog v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown" max-width="430">
      <template v-slot:activator="{ on }">
        <button class="action" @click="openModal" v-on="on">VER</button>
      </template>
      <v-card class="contentCard">
        <v-card-title class="headline">
          <v-col cols="10" class="title-receipts">Solicitação de Pagamento</v-col>
          <v-spacer></v-spacer>
          <v-col class="icon" cols="1">
            <v-icon @click="closeModal" class="close-icon">mdi-window-close</v-icon>
          </v-col>
        </v-card-title>

          <v-divider class="mx-6 ml-6 divider divider-consult"></v-divider>

        <v-col>
          <v-alert class="alert" dense outlined v-if="responseError">{{responseError}}</v-alert>
        </v-col>
        <v-card-text>
          <v-container class="contentForm" v-if="showModal">
            <v-form class="form" ref="form"  @submit.prevent="submit" >
              <v-row>
                <v-col cols="12" class="iconSearchFilters">
                  <v-text-field
                    readonly
                    v-model="dataSupplier.document"
                    label="CNPJ"
                    v-mask="'##.###.###/####-##'"
                    :rules="validationCNPJ"
                    placeholder="Digite o CNPJ"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="iconSearchFilters">
                  <v-text-field
                    readonly
                    v-model="dataSupplier.sellerName"
                    label="Razão social"
                    :rules="validationSocial"
                    placeholder="Preenchimento automatico"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="iconSearchFilters">
                  <v-text-field
                    readonly
                    v-model="dataSupplier.externalId"
                    type="number"
                    label="Pedido"
                    :rules="validationRequest"
                    placeholder="Digite o número do pedido"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="iconSearchFilters">
                  <v-text-field
                    readonly
                    v-model="dataSupplier.value"
                    label="Valor solicitado"
                    v-money="money"
                    :rules="validationValue"
                    validate-on-blur
                    required
                  ></v-text-field>
                </v-col>
                <v-row v-if="dataSupplier.files.length > 0">
                  <v-col md="11" cols="11">
                    <v-icon>mdi-clipboard-outline</v-icon>Total de anexos para download:
                    <span
                      style="font-weight:bold"
                    >{{dataSupplier.files.length}}</span>
                  </v-col>
                  <v-col md="8" cols="12" sm="6">
                    <v-select
                      class="select-items"
                      :items="dataSupplier.files"
                      v-model="filesPreview"
                      label="Selecionar anexo"
                      dense
                      outlined
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip>
                          <span>{{`${item.link.split('/')[6].substr(0, 20)}...`}}</span>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-chip>
                          <span>{{`${item.link.split('/')[6].substr(0, 20)}...`}}</span>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col md="3">
                    <div class="action_file" @click="downloadAttachment">Download</div>
                  </v-col>
                </v-row>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mask } from 'vue-the-mask';
import { VMoney } from 'v-money';
import { mapActions, mapGetters } from 'vuex';
import service from '../../../service';

export default {
  directives: {
    mask,
    money: VMoney,
  },
  props: ['dataSupplier'],
  data() {
    return {
      btnLoadingAprove: false,
      btnLoadingReprove: false,
      showModal: true,
      dialog: false,
      singleStatus: true,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
      },
      dataRequest: {},
      requestError: 'requestError',
      document: '',
      service,
      filesPreview: '',
    };
  },
  watch: {
    dialog(value) {
      if (value === false) {
        this.closeModal();
      }
    },
  },
  computed: {
    ...mapGetters({
      export: 'Request/getExport',
      fileName: 'Request/getFileName',
    }),
    validationCNPJ() {
      return [
        value => !!value || 'CNPJ obrigatório',
        value => (value && value.length > 17) || 'Deve conter 14 números',
      ];
    },
    validationRequest() {
      return [value => !!value || 'Pedido obrigatório'];
    },
    validationValue() {
      return [value => !!value || 'Valor obrigatório'];
    },
    validationSocial() {
      return [value => !!value || 'Razão social obrigatório'];
    },
    responseError() {
      return this.getError;
    },
  },
  methods: {
    ...mapActions({
      downloadFileAttached: 'Request/downloadFileAttached',
    }),
    closeModal() {
      this.dialog = false;
      this.showModal = false;
      this.filesPreview = '';
    },
    openModal() {
      this.showModal = true;
    },
    async downloadAttachment() {
      await this.downloadFileAttached(this.filesPreview.link);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/supplier/solicitation.scss';
</style>
